import './App.css';
import 'react-chatbot-kit/build/main.css'
import ChatBot from './components/chatbot';

function App() {

  return (<ChatBot/>);
}

export default App;
